<template>
  <div id="mytable">
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>网授课程</el-breadcrumb-item>
      <el-breadcrumb-item>考试记录</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="top">
        <el-col :span="20">
          <el-form :inline="true" class="top">
            <el-form-item label="用户ID">
              <el-input
                placeholder="请输入搜索内容"
                v-model="quid"
                clearable
                width="40"
              >
              </el-input>
            </el-form-item>

            <el-form-item label="课程ID">
              <el-input
                clearable
                placeholder="搜索课程ID"
                v-model="kc_id"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否合格">
              <el-select v-model="is_ok" clearable placeholder="请选择">
                <el-option
                  v-for="(item2, index2) in validArr"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="success" @click="onSubmit"
            >搜索</el-button
          >
        </el-col>
        <el-col :span="4">
          <el-button size="mini" round type="warning" @click="onAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <!--表格数据区-->
      <div>
        <el-table
          v-loading="isLogin"
          :border="true"
          :data="tableData"
          highlight-current-row
          stripe
          :resizable="true"
          :height="getH()"
        >
          <el-table-column
            v-for="(item, index) in propList"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            :min-width="item.width"
            :data-type="item.type"
            align="left"
            stripe
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else class="titleDiv">
                {{ scope.row[item.prop] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button type="text" @click="onEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button type="text" @click="onDel(scope.row)">
                  删除</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--底部分页区-->
      <el-pagination
        v-if="tableData.length > 0"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <!--编辑描述窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :label="!editItem.uid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="400"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      otherHeight: 300,
      //搜索
      quid: "",
      kc_id: "",
      is_ok: "",
      searchKey: "",
      validArr: ["否", "是"],
      //表格字段

      /**
   * DROP TABLE IF EXISTS `zy_kaoshi_log`;
   CREATE TABLE `zy_kaoshi_log`  (
  `id` int(11) NOT NULL AUTO_INCREMENT,
  `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
  `uid` int(11) NOT NULL DEFAULT 0 COMMENT '用户ID',
  `kc_id` int(11) NOT NULL DEFAULT 0 COMMENT '课程ID',
  `shijuan_id` int(11) NOT NULL DEFAULT 0 COMMENT '试卷ID',
  `score` int(11) NOT NULL DEFAULT 0 COMMENT '得分',
  `use_time` int(11) NOT NULL DEFAULT 0 COMMENT '做题时间',
  `is_ok` int(11) NOT NULL DEFAULT 0 COMMENT '是否合格',
  `ok_num` int(11) NOT NULL DEFAULT 0 COMMENT '正确数量',
  `err_num` int(11) NOT NULL DEFAULT 0 COMMENT '错题数量',
  `tm_ids` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '题目ID串',
  `answer_strs` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '回答选项串',
  `bz` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '备注',
  PRIMARY KEY (`id`) USING BTREE
) ENGINE = InnoDB AUTO_INCREMENT = 8 CHARACTER SET = utf8mb4 COLLATE = utf8mb4_general_ci ROW_FORMAT = Dynamic;
 */

      propList: [
        {
          prop: "id",
          label: "ID",
          width: 80,
        },
        {
          prop: "add_time",
          label: "添加时间",
          width: 180,
          type: "time",
        },

        {
          prop: "uid",
          label: "用户ID",
          width: 100,
        },
        {
          prop: "wx_nick",
          label: "用户微信",
             width: 150,
        },
        {
          prop: "kc_id",
          label: "课程ID",
          width: 100,
        },
        {
          prop: "kc_name",
          label: "课程名称",
          width: 180,
        },
        {
          prop: "shijuan_id",
          label: "试卷ID",
          width: 100,
        },
        {
          prop: "score",
          label: "得分",
        },
        {
          prop: "use_time",
          label: "做题时间(秒)",
          width: 130,
        },
        {
          prop: "ok_num",
          label: "正确数量",
          width: 100,
        },
        {
          prop: "err_num",
          label: "错题数量",
          width: 150,
        },
        {
          prop: "is_ok",
          label: "是否合格",
          width: 100,
          type: "array",
          data: ["否", "是"],
        },
        {
          prop: "err_num",
          label: "题目ID串",
          width: 130,
        },
        {
          prop: "answer_strs",
          label: "回答选项串",
          width: 130,
        },
        {
          prop: "bz",
          label: "备注",
          width: 150,
          type: "textarea",
        },
      ],
      //表格数据
      tableData: [],
      //总总数
      total: 0,
      //当前的页数
      page: 1,
      //当前的页码显示的条数
      pageSize: 10,
      //最后页号
      last_page: 0,
      //屏幕高度 document.body.clientHeight
      screenHeight: 0,
      //是否获取字段
      isGetFeild: false,
      //正在加载
      isLogin: false,
      //显示侧边栏
      showEditDlg: false,
      //编辑的项目
      editItem: {},
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 240;
    },
    //监听对话框
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },
    //获取表数据
    async getList(page = 1) {
      this.isLogin = true;
      const postData = {
        page,
        pageSize: this.pageSize,
        quid: this.quid,
        kc_id: this.kc_id,
        is_ok: this.is_ok,
      };

      const res = await this.$post("KaoShiLog/getList", postData);
      console.log(res, "kkkkk");
      this.isLogin = false;
      this.tableData = res.data.data;

      this.total = res.data.total;
    },
    ////点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.page = newPage;
      this.getList(this.page);
    },
    //取标题，有括号的去除
    /** getTitleLabel(val) {
      //console.log(val)
      if (!val) return "";
      let index = val.lastIndexOf("(");
      if (index > -1) {
        val = val.substring(0, index);
      }
      return val;
    }, */
    //编辑内容 展示编辑角色对话框 Dialog:对话框
    //添加内容
    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    onEdit(item) {
      console.log(item, "ooooo");
      this.editItem = JSON.parse(JSON.stringify(item));
      this.showEditDlg = true;
    },

    //保存
    async onSaveEdit() {
      this.showDrawer = false;
      let obj1 = {
        data: this.editItem,
        id: this.editItem.id,
      };
      this.showEditDlg = false;
      const res = await this.$post(`KaoShiLog/saveData`, obj1);
      console.log("保存返回", res);
      if (res.code !== 200) return;

      this.$ok(res.msg);
      //刷新
      this.getList();
    },
    //删除内容
    async onDel(item) {
      let id = item.id;
      const confirmResult = await this.$confirm(
        "此操作将永久删除该数据行:" + id + "?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$post(`KaoShiLog/delItem`, {
        id: item.id,
      });
      console.log("确认删除", res);
      if (res.code !== 200) return;

      //刷新
      this.getList(this.page);
    },
  },
};
</script>

<style lang="less">
.myFormDiv {
  .el-form-item {
    margin-bottom: 0;
  }
}
</style>
<style lang="less" scoped>
.titleDiv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.searchDiv {
  display: flex;
  align-items: center;
}

.myFormDiv {
  height: 100vh;
  overflow-y: scroll;
}

.myElCol {
  padding: 10px;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.checkItem {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  div:first-child {
    width: 100px;
  }
}
</style>
