import COS from 'cos-js-sdk-v5'
import { Message } from 'element-ui'
//import voteApi from "@/api/vote";
import axios from "axios";
//导入配置
import { upConfig, upDir } from '@/utils/upconfig.js'
console.log('upConfig', upConfig)
const config = upConfig
// 上传到腾讯云cos
/*
* file 选取的文件，
* fileCallback 文件上传过程中返回上传速度、进度以及文件名的方法，
* callback 文件上传成功后的回调方法，
* setBigFile 大文件上传初始化设置回调方法
*
* */
export function uploadObject(server, file, fileCallback, setBigFile, callback) {
    /*
     1.获取临时秘钥data
     2.初始化
     3.判断上传文件的类型
     4.判断文件大小 是否需要分片上传
     */
    let fileName = file.name || ""
    const origin_file_name = fileName.split(".").slice(0, fileName.split(".").length - 1).join('.') // 获取文件名称
    // console.log('origin_file_name', origin_file_name)
    // 获取当前时间戳 与文件类型拼接 为cos.putObject里的参数Key
    const upload_file_name = new Date().getTime() + '.' + fileName.split(".")[fileName.split(".").length - 1];
    let userId = file.userId
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let strDate = date.getDate()
    let fileType=fileName.split(".")[fileName.split(".").length - 1];
    let uploadDay = fileType+'_'+year //`${year}${month}${strDate}` //230422改为按文件后缀
    // 获取密钥
    console.log("请求服务器:" + server)
    axios.post(server).then(res => { // 后台接口返回 密钥相关信息
        console.log(res)
        const data = res.data.data
        var credentials = data && data.credentials
        if (!data || !credentials) return console.error('未获取到参数')
        // 初始化
        var cos = new COS({
            getAuthorization: (options, callback) => {
                callback({
                    TmpSecretId: credentials.tmpSecretId,
                    TmpSecretKey: credentials.tmpSecretKey,
                    XCosSecurityToken: credentials.sessionToken,
                    StartTime: data.startTime,
                    ExpiredTime: data.expiredTime,
                    expiration: data.expiration,
                    requestId: data.requestId,
                })
            },
        })
        // 获取上传文件大小
        let size = file.size
        let key = `/` + upDir + `/${uploadDay}/${upload_file_name}`
        // console.log('size', size)
        // console.log(size / (1024 * 2024))
        if (size / (1024 * 1024) < 5) { // 文件小于5M走普通上传
            console.log('文件普通上传', config)
            cos.putObject(
                {
                    Bucket: config.Bucket, // 存储桶名称
                    Region: config.Region, // 存储桶所在地域，必须字段
                    Key: key, // 文件名称
                    StorageClass: 'STANDARD',
                    Body: file, // 上传文件对象
                    // onHashProgress: (progressData) => {
                    //   console.log('校验中', JSON.stringify(progressData))
                    // },
                    onProgress: (progressData) => {
                        const percent = parseInt(progressData.percent * 10000) / 100;
                        const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                        // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                        fileCallback(percent, speed, origin_file_name)
                    },
                },
                (err, data) => {
                    if (err) {
                        console.log('err', err)
                        Message({ message: '文件上传失败,请重新上传', type: 'error' })
                        let fileUrl = null
                        callback(fileUrl, origin_file_name)
                    } else {
                        let fileUrl = 'https://' + data.Location
                        callback(fileUrl, origin_file_name) // 返回文件链接地址和视频的原始名称 上传完成后的回调
                    }
                }
            )
        } else {
            console.log('文件分块上传')
            // 上传分块
            cos.sliceUploadFile(
                {
                    Bucket: config.Bucket, // 存储桶名称
                    Region: config.Region, // 存储桶所在地域，必须字段
                    Key: key /* 必须 */,
                    Body: file,
                    onTaskReady: (taskId) => {
                        /* 非必须 */
                        setBigFile && setBigFile(cos, taskId, origin_file_name)
                    },
                    // onHashProgress: (progressData) => {
                    //     /* 非必须 */
                    //     // console.log(JSON.stringify(progressData))
                    // },
                    onProgress: function (progressData) {
                        const percent = parseInt(progressData.percent * 10000) / 100;
                        const speed = parseInt((progressData.speed / 1024 / 1024) * 100) / 100;
                        // console.log('进度：' + percent + '%; 速度：' + speed + 'Mb/s;');
                        fileCallback(percent, speed, origin_file_name)
                    },
                },
                (err, data) => {
                    console.log('上传返回', err, data)
                    if (err) {
                        // console.log(err)
                        Message({ message: '文件上传失败,请重新上传', type: 'error' })
                        let fileUrl = null
                        callback(fileUrl, origin_file_name)
                    } else {
                        let fileUrl = 'https://' + data.Location
                        callback(fileUrl, origin_file_name) // 返回文件链接地址和视频的原始名称 上传完成后的回调
                    }
                }
            )
        }
    })
}

export default {
    uploadObject
}
