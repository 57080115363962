<template>
  <div id="app">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>

<style>
.el-message-box__btns {
  display: flex;
  justify-content: flex-end;
}
.caoz {
  display: flex;
  justify-content: space-around;
}
.titleDiv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*弹出窗口的宽度*/
.el-message-box{
  max-width: 60vw;
  width: auto !important;
}

.el-message-box__content{
  max-height: 500px;
  overflow-y: auto;
  max-width: 59vw;
}
</style>
