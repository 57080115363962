<template>
  <div>
    <MyUploadPlus
      v-model="form.videoUrls"
      :accept="accept"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import MyUploadPlus from "./MyUploadPlus";
export default {
  components: { MyUploadPlus },
  name: "UpLoadMp4",
  props: {},
  data() {
    return {
      form: {
        videoUrls:[],
      },
      accept: "*",
      disabled: false,
    };
  },
  methods: {},
};
</script>

<style scoped>
</style>
