<template>
  <div>
    <!--面包屑-->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页 </el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
    </el-breadcrumb>

    <!--卡片区域-->
    <div>
      <el-card>
        <!--搜索区-->
        <el-row>
          <el-col :span="10">
            <el-form :inline="true" class="searchDiv">
              <el-form-item label="关键词">
                <el-input
                  clearable
                  placeholder="搜索关键词"
                  v-model="searchKey"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否图片">
                <el-select v-model="is_pic" clearable placeholder="请选择">
                  <el-option
                    v-for="(item2, index2) in validArr"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="2">
            <el-button size="mini" round type="success" @click="onSubmit"
              >搜索</el-button
            >
          </el-col>

          <el-col :span="2">

          </el-col>
        </el-row>
      </el-card>
      <!--表格区--->
      <el-table
        :data="tableData"
        :border="true"
        :height="getH()"
        stripe
        :resizable="true"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(item, index) in propList"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width ? item.width : 100"
        >
          <template slot-scope="scope">
            <!--操作类型数组-->
            <div
              v-if="
                scope.column['property'] == 'set_val' &&
                scope.row['is_pic'] == 1
              "
            >
              <el-image
                v-if="scope.row[scope.column['property']]"
                :src="$getUrl(scope.row[scope.column['property']])"
                fit="scale-down"
                style="height: 100px"
              />
            </div>
            <div v-else>
              {{ scope.row[scope.column["property"]] }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120">
          <template slot-scope="scope">
            <div class="caoz">
              <el-button type="text" size="small" @click="onEditItem(scope.row)"
                >编辑</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--添加dialog表格-->
    <!--编辑窗口-->
    <el-dialog
      v-if="showEditDlg"
      :close-on-click-modal="false"
      :title="!editItem.sid ? '新增' : '编辑'"
      :visible.sync="showEditDlg"
      width="300"
      :before-close="handleCloseDlg"
    >
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
            class="myElCol"
            :span="11"
            v-for="(item, index) in propList"
            :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                v-if="item.type == 'array'"
                v-model="editItem[item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in item.data"
                  :key="index2"
                  :label="item2"
                  :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                v-else-if="item.prop == 'power'"
                v-model="editItem[item.prop]"
                :collapse-tags="false"
                multiple
                placeholder="请选择"
              >
                <el-option
                  v-for="(item2, index2) in powerList"
                  :key="index2"
                  :label="item2"
                  :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                v-else-if="item.type == 'time'"
                v-model="editItem[item.prop]"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                v-else
                :type="item.type == 'textarea' ? 'textarea' : 'text'"
                :disabled="item.prop == 'id'"
                :rows="2"
                clearable
                v-model="editItem[item.prop]"
              >
              </el-input>
              <MyUploadPlus
                v-if="item.prop == 'set_val' && editItem['upload'] == true"
                v-model="form.videoUrls"
                accept="*"
                :disabled="false"
                @urlChange="onUrlChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSaveEdit">确 定</el-button>
      </div>
    </el-dialog>
    <!--底部分页区-->
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="queryInfo.pageNum"
      :page-sizes="[10, 30, 50, 100]"
      :page-size="queryInfo.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        videoUrls: [],
      },
      isVideo: "*",
      isDetail: true,
      //设置列表
      tableData: [],
      //获取设置列表 对像
      queryInfo: {
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
      },
      searchKey: "",
      is_pic: "",
      validArr: ["否", "是"],
      //总条数
      total: 0,
      /**
       * `zy_set`  (
  `sid` int(11) NOT NULL AUTO_INCREMENT,
  `set_name` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '',
  `set_val` text CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NULL,
  `set_title` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL,
  `is_pic` int(11) NOT NULL DEFAULT 0 COMMENT '是否图片',
  PRIMARY KEY (`sid`) USING BTREE
       */
      propList: [
        {
          prop: "sid",
          label: "设置ID",
        },
        {
          prop: "set_title",
          label: "设置标题",
          width: 250,
          type:'textarea'
        },
        /*
        {
          prop: "set_name",
          label: "设置名称",
          width:250
        },
        */
        {
          prop: "set_val",
          label: "设置值",
          width: 550,
          type:'textarea'
        },
        /* 
        {
          prop: "is_pic",
          label: "是否图片",
          type: "array",
          data: ["否", "是"],
        },
        */
      ],
      //是否布尔值,
      // is_pic: ["是", "否"],
      //新增招聘系统设置
      editItem: {
        sid: 0,
        set_title: "",
        set_name: "",
        set_val: "",
        // is_pic: "",
      },
      //点击 添加 聊天记录列表弹框 显示与影藏
      showEditDlg: false,

      //当前项里 设置类型是否逻辑型
      isBoolType: false,
      //当前项里 逻辑型设置值
      setValBool: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  //判断勾选
  watch: {
    "addForm.is_bool"(val) {
      if (val == 1) {
        this.isBoolType = true;
      } else {
        this.isBoolType = false;
      }
      console.log(val, this.isBoolType, "this.isBoolType");
    },
    "addForm.set_val"(val) {
      if (parseInt(val) == 1) {
        this.setValBool = true;
      } else {
        this.setValBool = false;
      }
      console.log(val, this.setValBool, "this.setValBool");
    },
    isBoolType(val) {
      this.addForm.is_bool = val == true ? 1 : 0;
    },
    setValBool(val) {
      if (this.isBoolType == true) {
        this.addForm.set_val = val == true ? 1 : 0;
      }
    },
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    //获取设置列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        searchKey: this.searchKey,
        is_pic: this.is_pic,
      };
      const res = await this.$post(`set/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;
      //是图片的，加上上传标识
      for (let i in this.tableData) {
        if (
          this.tableData[i]["is_pic"] == 1 ||
          this.tableData[i]["is_pic"] == 2
        ) {
          this.tableData[i]["upload"] = true;
        } else {
          this.tableData[i]["upload"] = false;
        }
      }
      this.total = res.data.total;
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.page = newPage;
      //获取用户列表数据
      this.getList();
    },

    //点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },
    //新增或修改聊天记录管理
    onEditItem(item) {
      console.log("玉米", item);
      if (item) {
        for (let i in item) {
          this.editItem[i] = item[i];
          console.log(i, this.editItem[i]);
        }
      } else {
        for (let i in this.editItem) {
          this.editItem[i] = "";
        }
      }
      this.showEditDlg = true;
    },
    onAdd() {},
    //
    async onSaveEdit() {
      for (let key in this.addForm) {
        console.log("hhh", key, this.addForm[key]);
        if (key !== "sid" && this.addForm[key].length < 1) {
          this.$err("内容不能空:" + key);
          //this.showEditDlg = false;
          return;
        }
      }

      let obj2 = {
        sid: this.editItem.sid,
        data: this.editItem,
      };

      //处理html代码
      obj2.data['set_val']=this.base64EnCode(obj2.data['set_val'])

      this.showEditDlg = false;
      const res = await this.$post(`set/saveData`, obj2);

      console.log("目赫尔", res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
    },
    //删除
    async onSystemRulesByID(sid) {
      //弹出对话框是否真的删除 此操作将永久该删除规则
      const confirmResult = await this.$confirm(
        "此操作将永久删除一行数据 ?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      const res = await this.$post(`set/delSet`, {
        sid,
      });
      console.log("删除", res);
      if (res.code !== 200) return;
      this.$ok("ok");
      this.getList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取规则列表数据
      this.getList();
    },
    handleCloseDlg() {
      this.showCheckDlg = false;
    },
    //链接改变了
    onUrlChange(e) {
      console.log("onUrlChange", e);
      this.editItem["set_val"] = e[0].url;
    },
    async onWrite() {
      const res = await this.$post(`set/writeSet`);
      console.log("onWrite", res);
      if (res.code !== 200) return;
      this.$ok(res.msg);
    },
  },
};
</script>

<style lang="less" scoped>
.delDiv {
  margin-top: 12px;
  width: 50%;
  display: flex;
  align-items: center;

  .el-input,
  .el-button {
    margin-left: 10px;
  }
}

.fDiv {
  div {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-tag {
      flex-basis: 1;
      width: 120px !important;
      margin-right: 10px;
    }

    div {
      flex-grow: 1;
    }
  }
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>
