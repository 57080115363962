<template>
  <div>
    <!--面包屑-->

    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>已购课程</el-breadcrumb-item>
    </el-breadcrumb>
    <!--卡片区域-->
    <el-card>
      <el-row type="flex" align="top">
        <el-col :span="16">
          <el-form :inline="true" class="searchDiv">
            <el-form-item label="用户ID">
              <el-input
                  clearable
                  placeholder="搜索用户ID"
                  v-model="quid"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程ID">
              <el-input
                  clearable
                  placeholder="搜索课程ID"
                  v-model="kc_id"
              ></el-input>
            </el-form-item>

            <el-form-item label="是否线下">
              <el-select v-model="isXianXia" clearable placeholder="请选择">
                <el-option
                    v-for="(item2, index2) in ['否','是']"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
            </el-form-item>

          </el-form>
        </el-col>
        <el-col :span="4">
          <el-row type="flex" justify="space-around">
            <el-button type="success" @click="onSubmit">搜索</el-button>
            <el-button type="primary" @click="onAdd">添加</el-button>

          </el-row>
        </el-col>
        <el-col :span="4" style="display: flex;justify-content: center;">
          <el-button type="info" @click="onRemove">移除过期记录</el-button>
        </el-col>
      </el-row>
      <!--表格区-->

      <div class="table">
        <el-table
            :data="tableData"
            :border="true"
            :height="getH()"
            stripe
            :resizable="true"
        >
          <el-table-column
              show-overflow-tooltip
              v-for="(item, index) in propList"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              :width="item.width ? item.width : 100"
          ><!--显示特殊字段-->
            <template slot-scope="scope">
              <div v-if="item.type == 'array'">
                {{ getOptionVal(item.data, scope.row[item.prop]) }}
              </div>

              <div v-else>
                {{ scope.row[scope.column["property"]] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <div class="caoz">
                <el-button
                    type="text"
                    size="small"
                    @click="onEditItem(scope.row)"
                >编辑
                </el-button
                >
                <el-button
                    type="text"
                    size="small"
                    @click="onDelItem(scope.row)"
                >
                  删除
                </el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--底部分页区-->
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </el-card>

    <!--添加dialog表格-->
    <el-dialog
        v-if="showEditDlg"
        :close-on-click-modal="false"
        :title="editItem.id > 0 ? '编辑' : '添加'"
        :visible.sync="showEditDlg"
        width="50%"
        :before-close="handleCloseDlg"
    ><!--表单区-->
      <el-form :inline="false" label-width="150px">
        <el-row>
          <el-col
              class="myElCol"
              :span="11"
              v-for="(item, index) in propList"
              :key="index"
          >
            <el-form-item :label="item.label">
              <el-select
                  v-if="item.type == 'array'"
                  v-model="editItem[item.prop]"
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in item.data"
                    :key="index2"
                    :label="item2"
                    :value="index2"
                >
                </el-option>
              </el-select>
              <el-select
                  v-else-if="item.prop == 'power'"
                  v-model="editItem[item.prop]"
                  :collapse-tags="false"
                  multiple
                  placeholder="请选择"
              >
                <el-option
                    v-for="(item2, index2) in powerList"
                    :key="index2"
                    :label="item2"
                    :value="item2"
                >
                </el-option>
              </el-select>

              <el-date-picker
                  v-else-if="item.type == 'time'"
                  v-model="editItem[item.prop]"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择日期时间"
              >
              </el-date-picker>
              <el-input
                  v-else
                  :type="item.type == 'textarea' ? 'textarea' : 'text'"
                  :disabled="item.prop == 'id'"
                  :rows="2"
                  clearable
                  v-model="editItem[item.prop]"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <!--底部-->
      <div class="dialog-footer mybtn">
        <el-button @click="showEditDlg = false">取 消</el-button>
        <el-button type="primary" @click="onSave()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //获取推广记录列表列表对象
      queryInfo: {
        query: "",
        //当前的页数
        pageNum: 1,
        //当前的页码显示的条数
        pageSize: 10,
      },
      quid: "",
      kc_id: "",
      isXianXia: '',
      //是否有效
      validArr: ["未使用", "有效"],
      //总数据条数
      total: 0,
      //购买记录列表
      buylistList: [],
      tableData: [],
      /**
       *  `zy_buy_list`
       *  `id` int(11) NOT NULL AUTO_INCREMENT,
       `add_time` timestamp NOT NULL DEFAULT CURRENT_TIMESTAMP COMMENT '添加时间',
       `uid` int(11) NOT NULL DEFAULT 0 COMMENT '用户ID',
       `kc_id` int(11) NOT NULL DEFAULT 0 COMMENT '课程ID',
       `buy_money` decimal(11, 2) UNSIGNED NOT NULL DEFAULT 0.00 COMMENT '购买价格',
       `order_id` int(11) NOT NULL DEFAULT 0 COMMENT '订单ID',
       `kaoshi_ok` int(11) NOT NULL DEFAULT 0 COMMENT '是否考试通过',
       `isXianXia` int(11) NOT NULL DEFAULT '0' COMMENT '是否线下',
       `use_day` int(11) NOT NULL DEFAULT '0' COMMENT '有效天数',
       `bz` varchar(255) CHARACTER SET utf8mb4 COLLATE utf8mb4_general_ci NOT NULL DEFAULT '' COMMENT '备注',
       PRIMARY KEY (`id`) USING BTREE
       */
      propList: [
        {
          prop: "id",
          label: "ID",
        },
        {
          prop: "isXianXia",
          label: "是否线下",
          type: "array",
          data: ["否", "是"],
          width: 80,
        },
        {
          prop: "add_time",
          label: "购买时间",
          width: 200,
          type: "time",
        },

        {
          prop: "uid",
          label: "用户ID",
        },
        {
          prop: "wx_nick",
          label: "用户微信",
        },
        {
          prop: "kc_id",
          label: "课程ID",
        },
        {
          prop: "kc_name",
          label: "课程名称",
          width: 250,
        },
        {
          prop: "buy_money",
          label: "购买价格",
        },
        {
          prop: "order_id",
          label: "订单ID",
          width: 200,
        },
        {
          prop: "kaoshi_ok",
          label: "是否考试通过",
          type: "array",
          data: ["否", "是"],
          width: 120,
        },
        {
          prop: "use_day",
          label: "有效天数",

        },
        {
          prop: "bz",
          label: "备注",
        },
      ],

      //新增购买记录对像
      editItem: {
        add_time: "",
        uid: "",
        order_id: "",
        kc_id: "",
        id: 0,
      },
      // 时间
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      //点击添加推广记录列表弹框 显示与影藏
      showEditDlg: false,
    };
  },
  created() {
    //窗体大小变化就执行getH方法
    window.addEventListener("resize", this.getH);
    this.getList();
  },
  methods: {
    //调整table 高度
    getH() {
      return window.innerHeight - 280;
    },
    handleCloseDlg() {
      console.log("handleCloseDlg");
      this.showEditDlg = false;
    },

    //获取购买列表
    async getList() {
      let obj = {
        page: this.queryInfo.pageNum,
        pageSize: this.queryInfo.pageSize,
        quid: this.quid,
        kc_id: this.kc_id,
        isXianXia: this.isXianXia
      };
      const res = await this.$post(`BuyList/getList`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.tableData = res.data.data;
      this.total = res.data.total;
    },
    ////点击搜索显示搜索内容
    onSubmit() {
      this.getList();
    },

    //监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      //console.log(newSize)
      this.queryInfo.pageSize = newSize;
      this.getList();
    },

    //监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      //console.log(newPage)
      this.queryInfo.pageNum = newPage;
      //获取客服管理列表数据
      this.getList();
    },
    //先判断选择为 是 编辑 还是 添加
    //如果gid > 0是编辑, 则为添加
    // 展示编辑角色对话框 Dialog:对话框
    onEditItem(item) {
      this.editItem = JSON.parse(JSON.stringify(item));

      this.showEditDlg = true;
    },

    onAdd() {
      console.log("onAdd");
      let obj = {};
      for (let i in this.propList) {
        let prop = this.propList[i]["prop"];
        this.$set(obj, prop, "");
      }
      this.editItem = obj;
      this.showEditDlg = true;
    },
    //点击 确定 发起网络请求
    async onSave() {
      let obj = {
        id: this.editItem.id,
        data: {
          ...this.editItem,
        },
      };
      const res = await this.$post(`BuyList/saveData`, obj);
      console.log(res);
      if (res.code !== 200) return;
      this.$ok("保存成功");
      this.getList();
      this.showEditDlg = false;
    },
    //删除用户ID
    async onDelItem(item) {
      //弹出对话框是否真的删除 此操作将永久该删除记录
      const confirmResult = await this.$confirm(
          "此操作将永久删除记录?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
      ).catch((err) => err);
      //如果用户确认删除,则返回值为字符串  confirm
      //如果用户取消删除,则返回值为字符串  cancel
      //console.log( confirmResult );
      if (confirmResult !== "confirm") {
        return this.$message.info("已取消删除");
      }
      let obj = {
        id: item.id,
      };
      const res = await this.$post(`BuyList/delItem`, obj);
      console.log("确认删除", res);

      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    },
    //移除过期记录
    async onRemove() {
      const res = await this.$post(`BuyList/callRemoveOld`);
      if (res.code !== 200) return;
      this.$ok(res.msg);
      this.getList();
    }
  },
};
</script>
<style lang="less" scoped>
.delDiv {
  display: flex;
  align-items: center;

  .el-select,
  .el-input,
  .el-button {
    margin-left: 10px;
  }
}

.mybtn {
  display: flex;
  justify-content: flex-end;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>