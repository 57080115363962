<template>
  <div class="upload">
    <div class="file-upload">
      <el-upload
        ref="upload"
        :disabled="disabled"
        :accept="accept"
        action="#"
        :show-file-list="false"
        :http-request="uploadToCos"
        :before-upload="beforeImageUpload"
        :on-change="onChangeHandle"
      >
        <el-button size="small" type="primary">点击上传</el-button>
      </el-upload>

      <div class="file-list" v-if="isShowFile">
        <file-item
          v-for="file in value"
          :key="file.id || file.fileUrl"
          :file="file"
          :disableDel="true"
          showDownBtn
          @remove="removeFile(file)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { uploadObject } from "@/utils/upload.js";
import { Message } from "element-ui";
import FileItem from "./FileItem";
export default {
  name: "MyUploadPlus",
  components: { FileItem },
  data() {
    return {
      imgWidth: 0,
      imgHeight: 0,
      picIndex: -1,
      dialogImageUrl: "",
      dialogVisibleShow: false,
      fileList: [],
      isUpload: true,
      fileName: "",

      isShowFile: false, //显示文件列表
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: "",
    },
    limit: {
      type: Number,
      default: 100,
    },
    server: {
      type: String,
      default: "",
    },
  },
  created() {},
  methods: {
    removeFile(file) {
      if (file) {
        // remark: 这里是根据文件名来删除的，因为可能出现没有上传完的大文件临时删除，这时候没有url就会造成删不掉的情况
        const fileIndex = this.value.findIndex((i) => i.name === file.name);
        // remark: 这里是终止上传大文件的操作
        if (file.taskId) {
          let taskId = this.value[fileIndex].taskId;
          file.cos.cancelTask(taskId);
        }
        this.value.splice(fileIndex, 1);
        this.$emit("input", this.value);
        this.$emit("urlChange", this.value);
        // console.log(this.value)
        file.cancel && file.cancel();
      } else {
        this.value.length = 0;
        this.$emit("input", this.value);
        this.$emit("urlChange", this.value);
      }
    },
    onChangeHandle(file, fileList) {
      this.fileList = [file];
      console.log("onChangeHandle file, fileList", fileList);
      this.$refs.upload.$refs["upload-inner"].handleClick();
    },
    beforeImageUpload(file) {
      let fileName = this.getFileName(file.name);
      let idx = this.value.findIndex((e) => e.name === fileName);
      if (idx != -1) {
        this.fileName = this.value[idx].name;
      }
      this.isUpload = idx === -1 ? true : false;
    },
    // 获取选取文件的文件名
    getFileName(name) {
      return name.substring(0, name.lastIndexOf("."));
    },
    // 上传文件
    uploadToCos() {
      if (this.isUpload) {
        console.log("准备上传:" + this.$serverUrl);
        uploadObject(
          this.$serverUrl,
          this.fileList[0].raw,
          this.fileProgressCallback,
          this.setBigFile,
          (url, fileName) => {
            // console.log('files', this.fileList[0].raw)
            let index = this.value.findIndex((e) => e.name === fileName);
            if (url) {
              this.value[index].url = url;
              this.$emit("input", this.value);
              this.$emit("urlChange", this.value);
              Message.success({
                message: `${fileName}已上传完成`,
                offset: 300,
              });
            } else {
              this.value.splice(index, 1); // 文件上传失败删除fileitem列表显示文件
            }
          }
        );
      } else {
        Message.error({
          message: `${this.fileName}已存在,请勿重复上传`,
          offset: 300,
        });
      }
    },
    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
      console.log("speed=====>", speed);
      let file = {
        name: name,
        uploadProgress: progress,
      };
      if (this.value && this.value.length > 0) {
        let index = this.value.findIndex((e) => e.name === name);
        if (index >= 0) {
          this.value[index].uploadProgress = progress;
        } else {
          this.value.push(file);
        }
      } else {
        this.value.push(file);
      }
      //如要上传成功，则不显示了
      if (progress >= 100) {
        this.isShowFile = false;
      } else {
        this.isShowFile = true;
      }
    },
    // 大文件上传需要记录文件所对应的taskId和cos，这里我是直接给进度条 uploadProgress 做了一个初始化
    setBigFile(cos, taskId, fileName) {
      let file = {
        name: fileName,
        taskId: taskId,
        uploadProgress: 0,
        cos: cos,
      };
      let index = this.value.findIndex((e) => e.name === fileName);
      if (index >= 0) {
        this.value[index].taskId = taskId;
        this.value[index].cos = cos;
        this.value[index].uploadProgress = 0;
      } else {
        this.value.push(file);
      }
    },
  },
};
</script>

<style lang='less'>
@small-size: 80px;
.file-upload {
  max-width: 600px;
}
.file-list {
  padding-top: 20px;
  height: auto;
}
</style>
